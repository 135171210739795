import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { AdvertisingAd, OikotieData, RecommendationItem } from '../../server/types.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AdvertisingSlotComponent, AdvertisingNativeSlotComponent } from '@schibsted-nmp/advertising-companion';
import WarpElement from '@warp-ds/elements-core';
import './oikotie-section.js';
import { i18n } from '@lingui/core';

@customElement('advertising-card')
export class AdvertisingCard extends WarpElement {
	static styles = [
		...WarpElement.styles,
		css`
        /** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
        *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static}.hover\:s-bg-hover:hover{background-color:var(--w-s-color-background-hover)}.active\:s-bg-active:active{background-color:var(--w-s-color-background-active)}.hover\:s-border-hover:hover{border-color:var(--w-s-color-border-hover)}.active\:s-border-active:active{border-color:var(--w-s-color-border-active)}.hover\:s-surface-elevated-200-hover:hover{background-color:var(--w-s-color-surface-elevated-200-hover);box-shadow:var(--w-s-shadow-surface-elevated-200-hover)}.active\:s-surface-elevated-200-active:active{background-color:var(--w-s-color-surface-elevated-200-active);box-shadow:var(--w-s-shadow-surface-elevated-200-active)}.isolate{isolation:isolate};
		`
	];

	item!: RecommendationItem;
	oikotieData!: OikotieData;
	url!: string;
	data!: { items: RecommendationItem[] };

	static properties = {
		item: { type: Object },
		oikotieData: { type: Object },
		url: { type: String },
		data: { type: Object }
	}

	private mountedAds = new Set<string>();
	private pendingAds: AdvertisingAd[] = [];

	firstUpdated() {
		if (this.item.type === 'oikotie') {
			const oikotieAd = this.shadowRoot?.querySelector('#oikotie-ad');
			oikotieAd?.insertAdjacentHTML(
				'afterbegin',
				`<iframe
					title="${i18n.t({
					id: 'frontpage.oikotie.ad.title',
					message: 'Oikotie ad',
				})}"
					src="https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html"
					class="relative isolate recommendation-ad card card--cardShadow s-bg"
					width="100%"
					height="100%"
				></iframe>`
			);
		}
	}

	render() {
		if (!this.item) return html``;

		// Handle special ad types
		if (this.item.type === 'oikotie') {
			return this.oikotieAd();
		}

		if (this.item.type === 'oikotie-section') {
			return html`<oikotie-section
				.oikotieData=${this.oikotieData}
				.url="${this.url}"
			></oikotie-section>`;
		}

		// Standard ad logic
		const divId = this.item.itemId.replace(/:/g, '-');
		if (!this.mountedAds.has(divId)) {
			this.pendingAds.push({ adId: divId, adType: this.item.type });
		}

		return html`<div id="${divId}"></div>`;
	}

	// Lifecycle method: Handle pending ads and render them dynamically
	protected updated(changedProperties: Map<string, unknown>): void {
		super.updated(changedProperties);

		// Mount any pending ads
		for (const pendingAd of this.pendingAds) {
			const adId = pendingAd.adId;
			const advertisingAdRef = this.shadowRoot?.querySelector(`#${CSS.escape(adId)}`);

			if (advertisingAdRef) {
				const item = this.data?.items.find((item) => item.itemId.replace(/:/g, '-') === adId);
				if (!item) continue;

				const root = createRoot(advertisingAdRef);
				const categories = {
					main_category: item.content?.main_category,
					sub_category: item.content?.sub_category,
					prod_category: item.content?.prod_category,
				};

				// Render ad using the correct ad component
				if (pendingAd.adType === 'NATIVE') {
					root.render(
						<AdvertisingNativeSlotComponent
							itemId={adId}
							categories={JSON.stringify(categories)}
							initialLayoutType={'grid'}
							webComponentName={'frontpage-recommendations'}
						/>
					);
				} else {
					root.render(
						<AdvertisingSlotComponent
							itemId={adId}
							categories={JSON.stringify(categories)}
							initialLayoutType={'grid'}
							webComponentName={'frontpage-recommendations'}
						/>
					);
				}

				this.mountedAds.add(adId);
				const adIndex = this.pendingAds.findIndex((ad) => ad.adId === adId);
				this.pendingAds.splice(adIndex);
			}
		}
	}

	// Render the Oikotie ad
	private oikotieAd() {
		return html`
			<div
				class="h-full cursor-pointer overflow-hidden relative transition-all group rounded-8 s-surface-elevated-200 hover:s-surface-elevated-200-hover active:s-surface-elevated-200-active s-bg hover:s-bg-hover active:s-bg-active s-border hover:s-border-hover active:s-border-active"
				id="oikotie-ad"
				tabindex="0"
			></div>
		`;
	}
}