import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { createRoot } from 'react-dom/client';
import React from 'react';
import createHeartComponent from '@finn-no/favorite-heart-component';
import { RecommendationItem } from '../../server/types.js';
import WarpElement from '@warp-ds/elements-core';
import { isItemCMS } from './utils/index.js';

@customElement('favorite-heart')
export class FavoriteHeart extends WarpElement {
	static styles = [
		...WarpElement.styles,
		css`
        /** TODO: Remove this hard-coded style for favorite-heart once favorite-heart is rewritten **/
        /* layer: preflights */

        *, ::before, ::after {
            --w-rotate: 0;
            --w-rotate-x: 0;
            --w-rotate-y: 0;
            --w-rotate-z: 0;
            --w-scale-x: 1;
            --w-scale-y: 1;
            --w-scale-z: 1;
            --w-skew-x: 0;
            --w-skew-y: 0;
            --w-translate-x: 0;
            --w-translate-y: 0;
            --w-translate-z: 0;
        }

        /* layer: default */

        .rounded-l-8 {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .flex-row {
            flex-direction: row;
        }

        .flex-row-reverse {
            flex-direction: row-reverse;
        }

        .gap-16 {
            gap: 1.6rem;
        }

        .col-span-5 {
            grid-column: span 5/span 5;
        }

        .line-clamp-2 {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
        }

        .outline {
            outline-style: solid;
        }

        .s-icon-subtle {
            color: var(--w-s-color-icon-subtle);
        }

        .mb-16 {
            margin-bottom: 1.6rem;
        }

        .mb-8 {
            margin-bottom: 0.8rem;
        }

        .ml-14 {
            margin-left: 1.4rem;
        }

        .mr-12 {
            margin-right: 1.2rem;
        }

        .mt-24 {
            margin-top: 2.4rem;
        }

        .p-24 {
            padding: 2.4rem;
        }

        .pb-24 {
            padding-bottom: 2.4rem;
        }

        .pt-32 {
            padding-top: 3.2rem;
        }

        .object-cover {
            object-fit: cover;
        }

        .text-ellipsis {
            text-overflow: ellipsis;
        }

        .whitespace-nowrap {
            white-space: nowrap;
        }

        @media (max-width: 479.9px) {
            .lt-sm\\:min-w-full {
                min-width: 100%;
            }
        }
        @media (min-width: 480px) {
            .sm\\:col-span-2 {
                grid-column: span 2/span 2;
            }

            .sm\\:col-span-3 {
                grid-column: span 3/span 3;
            }
        }
        @media (min-width: 768px) {
            .md\\:grid-cols-2 {
                grid-template-columns:repeat(2, minmax(0, 1fr));
            }

            .md\\:grid-cols-3 {
                grid-template-columns:repeat(3, minmax(0, 1fr));
            }
        }
        /** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
        *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`
	];

	item!: RecommendationItem;
	favoriteBaseUrl!: string;
	loginId!: string;
	wcRef!: HTMLDivElement;

	static properties = {
		item: { type: Object },
		favoriteBaseUrl: { type: String },
		loginId: { type: String },
		wcRef: { type: Object },
	};

	async firstUpdated() {
		if (isItemCMS(this.item)) return;

		const Heart = createHeartComponent({
			baseUri: this.favoriteBaseUrl,
			renderServerSide: true,
			userId: this.loginId,
			modalAttach: this.wcRef,
		});

		// Wait for Lit to finish rendering before querying the DOM
		await this.updateComplete;
			const heartRef = this.shadowRoot?.querySelector(`#heart-${this.item?.itemId}`);
			if (heartRef) {
				const root = createRoot(heartRef);
				root.render(
					<Heart
						variant="heart-on-image"
						itemType="Ad"
						locale={this.locale as 'en' | 'nb' | 'da' | 'fi'}
						itemId={parseInt(this.item?.itemId)}
						isMobile={false}
						renderNumFavs={false}
					/>
				);
			}
	}

	render() {
		return html`
			<div class="absolute top-8 right-8" id=${`heart-${this.item?.itemId}`}></div>
		`;
	}
}

