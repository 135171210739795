import { html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { OverlayBadgeKey, RecommendationItem } from '../../server/types.js';
import WarpElement from '@warp-ds/elements-core';

@customElement('recommendation-badge')
export class RecommendationBadge extends WarpElement {
	static styles = [
		...WarpElement.styles,
		css`
		:host {
			display: block;
		}
        /** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
				*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static}.mr-4{margin-right:.4rem};
	`
	]

	item!: RecommendationItem;

	static properties = {
		item: { type: Object }
	}

	render() {
		if (!this.item) return html``;

		if (this.item.shippingOption?.label) {
			return html`
				<w-badge variant="warning" position="top-left">
					<w-icon-shipping-16 class="flex mr-4"></w-icon-shipping-16>
					${this.item.shippingOption.label}
				</w-badge>
			`;
		}

		const badgeKeys: OverlayBadgeKey[] = ['cms', 'p4p', 'easyapply', 'deadline'];

		const badgeMap: Record<OverlayBadgeKey, 'info' | 'positive'> = {
			cms: 'info',
			p4p: 'info',
			easyapply: 'positive',
			deadline: 'info',
		};

		for (const key of badgeKeys) {
			const label = this.item.overlay?.[key]?.label;
			if (label) {
				return html`
					<w-badge variant="${badgeMap[key]}" position="top-left">
						${label}
					</w-badge>
				`;
			}
		}

		return html``;
	}
}